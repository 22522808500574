/**
 * Page
  =========================================================================== */

.page {

  display: grid;
  grid-template-columns: 1fr minmax(auto, $vw_medium) 1fr;
  grid-gap: $spacing_far_way;
  padding-top: $spacing;
  padding-bottom: $spacing;
  overflow-x: hidden;

  & > * {
    grid-column: 2 / 3;
  }
}

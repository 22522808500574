/**
 * Footer
  =========================================================================== */

.footer {
  margin-top: $spacing_far_way;
  margin-bottom: $spacing_far_way;
  text-align: center;
  font-size: $font_size_small;
  @mixin line-height strip($font_size_small);
}

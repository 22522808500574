/**
 * Logo
  =========================================================================== */

.logo {

  grid-column: 2 / 3;
  animation: fadeIn .5s ease both;

  &__accent {
    fill: $color_accent;
  }

  &__primary {
    fill: $color_primary;
  }

  & > [data-animate='scaleUp'] {
    transform-origin: 50% 100%;
    animation: scaleUp .3s ease-in .2s both;
  }
}

/**
 * Common
  =========================================================================== */

body {
  text-align: justify;
  font-family: $font_system;
  font-variant-numeric: proportional-nums;
  @mixin fluid-size font-size, $font_size_normal, $font_size_medium, 400px, 1200px;
  line-height: $line_height;
  color: $color_text;
  background-color: $color_bg_primary;
}
h1 {
  @mixin line-height 2, 2;
  word-break: break-all;
  color: $color_accent;
  text-shadow: .15em .15em 0px $color_bg_primary, -.15em -.15em 0px $color_bg_primary;
  border-bottom: 1px solid;
}
a {

  color: $color_text;
  transition: color .3s ease;

  &:focus,
  &:hover {
    color: $color_primary;
  }
}
p,
ul {
  margin-top: 0;
  margin-bottom: $spacing_away;
}
p strong {
  color: $color_accent;
}
ul {
  padding-left: 0;
  list-style: none;
}
dl {
  display: inline-grid;
  grid-template-columns: minmax($font_size_normal, auto) 3fr;
  grid-row-gap: $spacing_close;
}

/**
 * Mixins
  =========================================================================== */

/**
 * Set a responsive property value.
 *
 * @param string $property Property name.
 * @param int    $min      Minimum value     (px/em/rem, same as maximum unit).
 * @param int    $max      Maximum value     (px/em/rem, same as minimum unit).
 * @param int    $vwmin    Starting viewport (px).
 * @param int    $vwmax    Ending viewport   (px).
 */
@define-mixin fluid-size $property, $min, $max, $vwmin, $vwmax {

  @media(width < $vwmin) {
    $(property): $min;
  }

  @media($vwmin <= width <= $vwmax) {
    $(property): calc($min + strip($max - $min) * ((100vw - $vwmin) / strip($vwmax - $vwmin)));
  }

  @media(width > $vwmax) {
    $(property): $max;
  }
}

/**
 * Set a scaled `line-height` by preserving vertical rythm.
 *
 * @param int $size  Element's font size (unitless).
 * @param int $scale Scale value.
 */
@define-mixin line-height $size, $scale: 1 {

  line-height: calc($scale * ((16 * $line_height) / (16 * $size)));
}

/**
 * Top bar
  =========================================================================== */

.top-bar {

  justify-self: end;
  font-size: $font_size_small;
  line-height: strip($font_size_small);

  & > * {
    grid-column: 2 / 3;
    margin-right: $spacing_close;
    color: $color_accent;
  }
}

/**
 * Slider (http://meandmax.github.io/lory/)
  =========================================================================== */

.slider {

  position: relative; /* Fix position of childs and pseudo elements. */
  white-space: nowrap;

  &__slide {
    display: inline-block;
  }

  &__control {

    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    padding: 0;       /* Reset browser default. */
    background: none; /* Reset browser default. */
    border: none;     /* Reset browser default. */
    @media (hover) {
      cursor: pointer;
    }
    @media not (hover) {
      pointer-events: none;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }

    & > .icon {
      font-size: 2rem;
      opacity: 0;
      transition: .3s ease;
      transition-property: transform, opacity;
    }

    &:hover > .icon {
      opacity: 1;
    }

    &:focus {
      outline: none; /* Reset browser default. */
    }

    &--next {
      right: 0;
      &:hover > .icon {
        transform: translateX(100px);
      }
    }
    &--prev {
      left: 0;
      &:hover > .icon {
        transform: translateX(-100px);
      }
    }
  }
}

/**
 * Contact
  =========================================================================== */

.contact {

  &__wrap {
    display: flex;
    justify-content: center;
    padding: $spacing_far_way;
    background: $color_bg_secondary;
    border-radius: .3rem;
  }

  & > dt {
    text-align: right;
    transform-origin: 100% 50%;
    &.animate {
      animation: fadeInBounce .5s ease-out both;
    }
  }

  & > dd {
    font-weight: bold;
    &.animate {
      animation: slideLeft .3s ease-out both;
    }
  }

  & > dt:nth-of-type(2),
  & > dd:nth-of-type(2) {
    animation-delay: .3s;
  }

  & > dt:nth-of-type(3),
  & > dd:nth-of-type(3) {
    animation-delay: .6s;
  }

  & > dt:nth-of-type(4),
  & > dd:nth-of-type(4) {
    animation-delay: .9s;
  }
}

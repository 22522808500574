/**
 * Icon
  =========================================================================== */

.icon {

  vertical-align: -0.2em;

  &--email,
  &--location,
  &--next,
  &--phone,
  &--prev {
    width: 1em;
    height: 1em;
  }

  &--next,
  &--prev {
    fill: $color_bg_primary;
  }

  &--phone,
  &--email,
  &--location {
    fill: $color_primary;
  }

  &--facebook {

    width: 10em;
    height: calc(21 / 100 * 10em); /* height / width * 10em */
    padding: $spacing_close;
    fill: $color_bg_primary;
    background-color: $color_accent;
    border-radius: 3px;
    transition-property: opacity;
    transition: .3s ease;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }

}

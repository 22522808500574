/**
 * Animations
  =========================================================================== */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInBounce {
  from {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.5);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(200%);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

/**
 * List
  =========================================================================== */

.list {

  display: flex;
  justify-content: center;
  align-items: center;

  &__item {

    display: block;
    @media (max-width: $vw_medium) {
      margin-right: $spacing;
      margin-left: $spacing;
    }
    @media (min-width: $vw_medium) {
      margin-right: $spacing_far_way;
      margin-left: $spacing_far_way;
    }
    opacity: .5;
    transition: opacity .3s ease;

    &:hover {
      opacity: 1;
    }
  }
}
